.current-conditions-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  justify-items: stretch;
  align-items: stretch;
  margin-bottom: 1rem;
}

.current-conditions-grid select {
  width: 100%;
}

@media (min-width: 768px) {
  .current-conditions-grid {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
    margin-bottom: 2rem;
  }
}

.sun-and-moon-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  justify-items: stretch;
  align-items: stretch;
  margin-bottom: 1rem;
}

@media (min-width: 768px) {
  .sun-and-moon-grid {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 32px;
    grid-row-gap: 32px;
    margin-bottom: 2rem;
  }
}

.condition-card-value {
  font-size: 3rem;
}

@media (min-width: 768px) {
  .condition-card-value {
    font-size: 7rem;
  }
}
